@media only screen and (max-width: 600px) {
    
.divprincipal {
    display:none;
}    
.logoMobile {
    display:block !important;
    margin-top: 200px;
    margin-left: 20px;
}
.logoMenor1 {
    width: auto !important;
}
.textocolunas {
    column-count: 1 !important;
    column-gap: 0px;
}
.aspasimg {
    width: 25px !important;
    height: auto;
}
.legenda {
    display:none;
}

  }