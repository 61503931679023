@font-face {
    font-family: "Gill Sans";
    src: url("../../fonts/GillSans.woff") format("woff"),
    url("../../fonts/GillSans.otf") format("opentype"),
}


.curriculumVitae {
    padding:20px;
    height: 100vh;
    margin-top: -100px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
    }
.Header {
    width: 100%;
    height:120px;
    border-bottom: 3px solid #ebebeb;
}
.HeaderEsq {
    float: left;
    width: 85%;
}
.HeaderDir {
    float:right;
    width:15%;
    text-align: right;
}
.Corpo {
    display:block;
    color: #747474;
}
.CorpoMobile {
    display:block;
    color: #747474;
    margin-top: 30px;
    margin-bottom: 400px;
}
.impressora {
    width:32px;
    height: auto;
    cursor: pointer;
}
.sessao {
    width: 100%;
    color: #747474;
    display: block;
    overflow:auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
}
.Corpo h2 {
    font-weight:300;
    text-align: center;
}
.CorpoMobile h2 {
    font-weight:300;
    text-align: center;
}
.sessao h2, h3 {
    font-weight: 300;
}
.sessaoInt {
    width:50%;
    float:left;
}
.sessaoIntMobile {
    width:50%;
}
.sessaoPeqEsq {
    width:10%;
    float:left;
    height: 65px;
}
.sessaoPeqDir {
    width:90%;
    float:left;
    height: 65px;
}
.sessaoPeqEsqMobile {
    width:20%;
    float:left;
    height: 65px;
}
.sessaoPeqDirMobile {
    width:80%;
    float:left;
    height: 65px;
}
.textoLaranja {
    color: #ff9c00;
    background-color: #ffffff;
}
.barraFundo {
    background-color: #ebebeb;
    width:300px;
    height:10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ff9c00;
}
.xlaranja {
    width: 100%;
    height: 10px;
    float:left;
}
.barraFrente {
    background-color: #747474;
    height: 10px;
    width:10px;
}
.naoimprimir {
    position: absolute;
    z-index: 999999999;
    right: 70px;
    top:8px;
}
.naoeditar {
    position: absolute;
    z-index: 999999999;
    right: 120px;
    top:8px;
}
.naoimpprimirvolta {

    position: absolute;
    z-index: 999999999;
    right: 15px;
    top:8px;
    border: none;
    background: none;
    cursor: pointer;
}
.Utils {
    position:fixed;
    z-index: 9999999;
    height: 50px;
    width: 245px;
    left:-21px;
    top:366px;
    

}
.imagens {
    width: 30px;
    height: auto;
    cursor:pointer;
}