
  @font-face {
    font-family: 'Typo Formal Shadowed Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Shadowed Demo'), url('./fonts/Typo Formal Shadowed Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Regular Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Regular Demo'), url('./fonts/Typo Formal Regular Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Light Italic Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Light Italic Demo'), url('./fonts/Typo Formal Light Italic Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Light Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Light Demo'), url('./fonts/Typo Formal Light Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Italic Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Italic Demo'), url('./fonts/Typo Formal Italic Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Bold Italic Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Bold Italic Demo'), url('./fonts/Typo Formal Bold Italic Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Bold Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Bold Demo'), url('./fonts/Typo Formal Bold Demo.woff') format('woff');
}  
    .flex-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100vw;
      background-image: url('./assets/bgNovo.png');
      background-position: center middle;
    }
    .centro {
      font-family: 'Typo Formal Regular Demo';
      width: 1080px;
      padding-top: 30px;
      display: flex;
      flex-direction: row;
    } 
    .centroMobile {
      font-family: 'Typo Formal Regular Demo';
      width: 300px;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
    } 
    .coluna1 {
      width: 265px;
    }
    .coluna2 {
      width: 263px;
    }
    .coluna3 {
      width: 279px;
      margin-top: 14px;
      margin-left: 10px;
    }
    .coluna4 {
      width: 276px;
      margin-left: 10px;
    }
    .cvBoto {
      margin-top: 430px; 
      border: 0; 
      cursor: pointer; 
      background-color: #ffffff;
    }
    .cvBotoMobile {
      margin-top: 30px; 
      border: 0; 
      cursor: pointer; 
      background-color: #ffffff;
    }
    .cvOthers {
      z-index: 20;
      display: flex;
      width: fit-content;
      flex-direction: row;
      justify-content: flex-start;
    }
    .cvOthersInterna {
      width: 141px !important;
      height: 97px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d6d6d6;
      margin-right: 10px;
      margin-top: 10px;
      border: 0;
      cursor: pointer;
    }
    .cvOthersInterna:hover {
      background-color: #fc8e00;
    }
    .curriculo {
      position: absolute;
      background-color: #ffffff;
      z-index: 100;
      border: 3px;
      border-color: #fc8e00;
      margin: 20px;
      display: flex;
      height: auto;
      padding: 20px;
      top: 0;
      left: 0;
    }
    .curriculoClose {
      position: absolute;
      right: 40px;
      top: 40px;
      z-index: 200;
      border: 0;
      background-color: #ffffff;
    }