@font-face {
    font-family: 'Typo Formal Shadowed Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Shadowed Demo'), url('../../fonts/Typo Formal Shadowed Demo.woff') format('woff');
}

.whatsapp {
    width: 100%;
    height: 400px;
}
.barraTop {
    width: 100%;
    height:28px;
    background-color: #ff8a00;
}
.barraTopEsq {
    float:left;
    padding:6px 8px;
    width: 20px;
}
.barraTopCentro {
    float:left;
    padding:8px 8px;
    width: calc(100% - 88px);
    justify-content: center;
    display: flex;
    font-size: 12px;
    font-family: 'Typo Formal Shadowed Demo';
}
.barraTopDir {
    float:left;
    font-family: 'Typo Formal Shadowed Demo';
    font-size: 14px;
    padding:6px 8px;
    width:20px;
    display: flex;
    justify-content: right;
}
.WhatsContent {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    overflow: hidden;
}
.barraBaixo {
    display: flex;
    background-color: #fc8e00;
    width: 245px;
    border-top: 1px solid #fc8e00;
    padding:10px;
    border-radius: 20px;
    border-top-right-radius: 0;
}
.barraBaixoEsq {
    width:100%;
    height:45px;
    margin-right: 10px;
    background-color:#fc8e00;
    float: left;
}
.barraBaixoDir {
    width:35px;
    height:35px;
    margin-right:10px;
    float: left;
}
.whatsInput {
    background-color: transparent;
    border: none;
    margin-left: 10px;
    padding: 10px 10px;
    width: 150px;
    color: #ffffff;
    border-bottom: #ffffff 1px solid;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.whatsInput::placeholder {
    color: #ffffff;
}
.Bloco {
    display: flex;
    width: calc(100% - 30px);
    padding:10px;
    flex-direction: column;
}
.eu {
    width: calc(100% - 10px);
    border-radius: 15px;
    border: 3px solid #ff8a00;
    padding:10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #fc8e00;
    font-size: 13px;
    font-weight: 600;
}
.euSeta {
    width: 100%;
    margin-left: 20px;
    margin-top: -7px;
}
.tu {
    width: calc(100% - 60px);
    border: 3px solid #dc00cf;
    padding:10px;
    border-radius: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #dc00cf;
    font-size: 13px;
    font-weight: 600;
    display:flex;
    align-self: end;
}
.tuSeta {
    width: 100%;
    margin-left: 200px;
    margin-top: -7px;
}