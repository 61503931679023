
@font-face {
    font-family: 'Typo Formal Shadowed Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Shadowed Demo'), url('../fonts/Typo Formal Shadowed Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Regular Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Regular Demo'), url('../fonts/Typo Formal Regular Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Light Italic Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Light Italic Demo'), url('../fonts/Typo Formal Light Italic Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Light Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Light Demo'), url('../fonts/Typo Formal Light Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Italic Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Italic Demo'), url('../fonts/Typo Formal Italic Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Bold Italic Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Bold Italic Demo'), url('../fonts/Typo Formal Bold Italic Demo.woff') format('woff');
}
@font-face {
    font-family: 'Typo Formal Bold Demo';
    font-style: normal;
    font-weight: normal;
    src: local('Typo Formal Bold Demo'), url('../fonts/Typo Formal Bold Demo.woff') format('woff');
}
@font-face {
  font-family: "Gill Sans";
  src: url("../fonts/GillSans.woff") format("woff"), url("../fonts/GillSans.otf") format("opentype"),
  }

.rabicho {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
    border-top-left-radius: 45px;
}
.rabicho1 {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
    border-top-left-radius: 0px;
}
.rabicho2 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
    border-top-left-radius: 45px;
}
.rabicho3 {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 45px;
}
.rabicho4 {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 45px;
    border-top-left-radius: 45px;
}
.boxInternoTitle {
    font-family: 'Typo Formal Regular Demo';
    font-size: 35px;
    margin-left: 45px;
}

.boxInternoSubTitle {
    font-family: 'Typo Formal Regular Demo';
    font-size: 21px;
    margin-left: 45px;
    margin-top: -10px;
}

/* 
    INICIA AS FUNCIONALIDADES CSS DO CARD
*/


.card {
    cursor: pointer;
  }
  
  .content {
    position: relative;
    transition: all 1.75s;
    padding: 6em;
    transform-style: preserve-3d;
    height:fit-content;
    border-radius: 15px;
  }
  
  .card:hover .content {
    transform: rotateY(0.5turn);
  }
  
  .front {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  .back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 190; 
    width: 265;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  
  .title {
    transform: translateZ(5rem);
    z-index: 9;
    position: absolute;
    bottom: 28px;
    padding-left: 20px;
  }
  .subtitle {
    transform: translateZ(2rem);
    position:absolute;
    z-index:5 ;
    padding-left: 20px;
    bottom: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size:13px;
    color: #a6a6a6;
  }
  
  .tipo {
    transform: translateZ(2rem);
    position:absolute;
    z-index:6 ;
    right: 20px;
    bottom: 28px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size:18px;
    color: #a6a6a6;
  }
  
  .back {
    transform: rotateY(0.5turn);
    background-color: #fff;
    
  }
  .front {
    background-position: center -40px;
  }
  .description {
    transform: translateZ(3rem);
    padding: 30px;
  }
  .tags {
    transform: translateZ(3rem);
    padding: 30px;
  }
  .cardlogo {
    transform: translateZ(3rem);
  }
  .Mais {
    position:relative !important;
  }
  .imgBg {
    width:270px;
    height: auto;
    border-radius: 20px;

  }
  .turn {
    width:250px;
    text-align: center;
    position: absolute;
    bottom:-80px;
  }
  .tag {
    background-color: #e7e7e7;
    border-radius: 10px;
    padding: 0px 10px;
    float: left;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    color:#333333;
  }
  /* ////////////////////////////////////////////////////////// */


.card2 {
  width: 100%;
  cursor: pointer;

}

.content2 {
  position: relative;
  transition: all 2.25s;
  transform-style: preserve-3d;
  height:fit-content;
}

.card2:hover .content2 {
  transform: rotateY(0.5turn);
}

.front2,
.back2 {
  position: absolute;
  top: -200px;
  left: 0;
  bottom: 0;
  right: 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.title2 {
  transform: translateZ(5rem);
  font-size: 16px;
  color: #000;
  z-index: 9;
  position: absolute;
  bottom: 28px;
  padding-left: 20px;
}
.cardBranco2 {
  width: 100%;
  height:60px;
  position:absolute;
  z-index:5 ;
  bottom: 0;
}
.subtitle2 {
  transform: translateZ(2rem);
  position:absolute;
  z-index:5 ;
  padding-left: 20px;
  bottom: 12px;
  font-size:13px;
  color: #a6a6a6;
}

.tipo2 {
  transform: translateZ(2rem);
  position:absolute;
  z-index:6 ;
  right: 20px;
  bottom: 28px;
  font-size:18px;
  color: #a6a6a6;
}

.back2 {
  transform: rotateY(0.5turn);
  background-color: #fbfbfb;
  height: 300px;
  border: 1px solid #e7e7e7;
  
}
.front2 {
  background-color: #fff;
  height: 300px;
}
.description2 {
  transform: translateZ(3rem);
}
.tags2 {
  transform: translateZ(3rem);
  padding:10px;
}
.cardlogo2 {
  transform: translateZ(3rem);
}
.Mais2 {
  position:relative !important;
}
.imgBg2 {
  width:270px;
  height: auto;
  border-radius: 20px;

}
.boxDetalhe {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 10%;
}

.detailColumn {
  width: 50%;
}

.OpacoDetail {
  backdrop-filter: blur(4px) saturate(152%);
  -webkit-backdrop-filter: blur(4px) saturate(152%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 9999;
  padding-top: 130px;
}
.OpacoDetailMobile {
  backdrop-filter: blur(4px) saturate(152%);
  -webkit-backdrop-filter: blur(4px) saturate(152%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  height: 10000px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 9999;
  padding-top: 130px;
}